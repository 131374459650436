<template>
  <v-main>
    <div class="fill-height">
      <div class="background-element background-image" :style="'background-image: url(' + backgroundUrl + ');'"></div>
      <div class="background-gradient background-element"></div>

      <v-container class="fill-height">
        <div class="pa-0 col-12 col-sm-8 col-md-8 col-lg-6 col-xl-5" style="margin: auto;">

          <div>
            <v-card color="c_background" style="width: 100%">
              <v-card-title class="">Account bestätigen</v-card-title>
              <v-card-text class="text-body-1">
                Wir haben eine E-Mail versendet<br>
                von <span class="black--text text-body-1">noreply@roy-spitzner.firebaseapp.com</span><br>
                an <span class="black--text text-body-1">{{ email }}</span><br>
                Bitte klicke in der E-Mail auf den Link um deine Registrierung abzuschließen.<br>
                Falls du keine E-Mail erhalten hast, sieh in deinem Spam-Ordner nach.
              </v-card-text>
            </v-card>
          </div>

        </div>
      </v-container>

    </div>
  </v-main>
</template>

<script>
import axios from "axios";
import firebase from "firebase/compat";

export default {
  name: "ConfirmAccount",
  components: {},
  data() {
    return {
      backgroundUrl: require("@/assets/images/climber_1080.jpg"),
    };
  },
  computed: {
    email() {
      return firebase.auth().currentUser.email;
    },
  },
  mounted() {
    let currentUser = firebase.auth().currentUser;
    if (currentUser) {
      firebase.auth().currentUser.sendEmailVerification()
    }
  },
  methods: {

  }
};
</script>

<style scoped>

.background-element {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.background-image {
  filter: saturate(80%) contrast(50%) brightness(80%);
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  overflow: hidden;
}

.background-gradient {
  background: rgb(0, 0, 0);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7427171552214635) 10%, rgba(0, 0, 0, 0) 55%);
}

</style>